.table {
  margin: 5px;
}
.btn-export {
  margin-top: 10px;
  float: right;
}
.data-sample-request {
  padding: 20px;
}
.data-item {
  font: 1.3em sans-serif;
  margin-top: 10px;
}
.form-item {
  margin-top: 30px;
}
.ant-form-item-label {
  text-align: center !important;
}

.ant-form-item {
  margin-bottom: 10px !important;
}
.form-select {
  margin-top: 30px;
}

.ant-table-thead > tr > th {
  text-align: center !important;
}

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
}

.site-layout-sub-header-background {
  background: #001529;
}

.site-layout-background {
  background: #fff;
}
.btn-logout {
  float: right;
  margin: 10px;
}

.btn-footer {
  float: right;
  padding: 10px 10%;
}

.btn-submit {
  margin-right: 10px !important;
}

.form-input-product {
  padding: 20px 0;
}
.container {
  padding: 3%;
}
.title {
  text-align: center;
}

.btn-down {
  text-align: center;
  padding: 5%;
}

.down-ws {
  margin: 0 20px;
}

.down-rp {
  margin: 0 20px;
}

.btn-link {
  text-align: center !important;
  height: 70px !important;
  width: 250px !important;
  margin: 20px 0;
  font-size: larger;
}
.ant-layout-sider-zero-width-trigger {
  background: #096dd9;
  top: 12px
}

.btn-admin {
  margin-top: 10px;
  margin-right: 15px;
  width: 130px;
}

.form-modal .ant-form-item-label {
  text-align: left !important;
}

.ant-layout {
  min-height: 100vh;
}

.add-sample {
  margin: 10px;
  float: right;
}
.search-input {
  width: 35%;
  margin: 10px;
  border-radius: 5px;
}

.group-btn-admin {display: inline-flex;}

.editable-cell {
  position: relative;
}

.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}

.editable-row:hover .editable-cell-value-wrap {
  padding: 4px 11px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}

[data-theme='dark'] .editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #434343;
}

.container-pdf {
  padding: 5% 12%;
}

.title {
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  padding: 20px 0;
}

.form-header {
  float: right;
}

.btn-pdf {
  margin: 0px 200px 50px 0;
  float: right;
}


.name {
  font-size: 16px;
  font-weight: 650;
}

.content {
  font-size: 16px;
  font-weight: 400;

}

.table {
  margin: 20px 0;
}

.title-table {
  font-size: 16px;
  margin: 10px 0;
}

.singned {
  text-align: center;
  font-size: 25px;
}
